import {
  TextInput as MantineInput,
  TextInputProps as MantineInputProps,
} from '@mantine/core'

interface TextInputProps extends MantineInputProps {
  editing?: boolean
  value?: string
  handleChange?: (event: any) => void
}

// This component extends Mantine's TextInput with the following:
// -Custom styling
// -Custom disabled/editing mode
const TextInput = ({
  editing,
  value,
  handleChange,
  ...props
}: TextInputProps) => {
  return (
    <MantineInput
      variant={editing ? 'default' : 'unstyled'}
      onClick={(event) => {
        if (editing) {
          event.stopPropagation()
        }
      }}
      classNames={{
        label: 'text-sm font-medium text-doubleNickel-gray-700',
        input: `cursor-pointer
      ${editing ? 'caret-inherit' : 'caret-transparent'}
       [&[data-disabled]]:bg-doubleNickel-white [&[data-disabled]]:opacity-100 [&[data-disabled]]:text-doubleNickel-gray-600`,
      }}
      value={value ?? '-'}
      onChange={(event) => {
        if (editing) {
          if (handleChange) {
            handleChange(event.target.value)
          } else {
            props.onChange(event)
          }
        }
      }}
      {...props}
    />
  )
}

export default TextInput
